<template>
  <div class="g-a-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="g-a-title"
          v-lazy="require('../../../assets/images/title8.png')"
        />
        <div class="g-a-dd1">
          <ul>
            <li
              v-for="(item,index) in tds"
              :key="index"
            >
              <div class="g-a-item">
                <img v-lazy="item.icon" />
              </div>
            </li>
          </ul>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GovernmentApproval",
  data() {
    return {
      tds: [
        { icon: require("../../../assets/images/zf1.png") },
        { icon: require("../../../assets/images/zf2.png") },
        { icon: require("../../../assets/images/zf3.png") },
        { icon: require("../../../assets/images/zf4.png") },
        { icon: require("../../../assets/images/zf5.png") },
        { icon: require("../../../assets/images/zf6.png") },
        { icon: require("../../../assets/images/zf7.png") },
        { icon: require("../../../assets/images/zf8.png") },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.g-a-container {
  width: 100%;
  background: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.g-a-title {
  width: 7rem;
  margin-top: 2.4rem;
}
.g-a-dd1 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2.4rem;
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    list-style: none;
    margin: 0px;
    margin-left: -0.3rem;
    li {
      margin-left: 0.3rem;
      margin-top: 0.3em;
      .g-a-item {
        width: 210px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>